export const environment = {
  production: true,
  apiUrl: 'https://api.technicalwater.app',
  baseDomainUrl: 'https://mobile.technicalwater.app/',

  // # secret
  JWT_ACCESS_SECRET: 'twmssServerSecret',
  JWT_REFRESH_SECRET: 'twmssServerRefreshSecret',
  JWT_LOGIN_SECRET: 'twmssServerLoginSecret',

  sentry: {
    enable: true,
    environment: 'production',
    dsn: 'https://83bb2d89df724265b194260fd1de0598@sentry.rawinlab.com/13',
    tracesSampleRate: 0.1,
  },
};
